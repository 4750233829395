var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Horizontal"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeListGroupHorizontal) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Set the prop")]), _c('code', [_vm._v("horizontal")]), _c('span', [_vm._v("to")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v("to change the layout of list group items from vertical to horizontal across all breakpoints. Alternatively, set")]), _c('code', [_vm._v("horizontal")]), _c('span', [_vm._v("to a responsive breakpoint")]), _c('code', [_vm._v("(sm, md, lg or xl)")]), _c('span', [_vm._v(" to make a list group horizontal starting at that breakpoint's min-width. Currently horizontal list groups cannot be combined with")]), _c('code', [_vm._v("flush")]), _c('span', [_vm._v("list groups.")])]), _c('b-alert', {
    attrs: {
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('span', {
    staticClass: "mr-25"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InfoIcon"
    }
  })], 1), _c('span', {
    staticClass: "ml-25"
  }, [_vm._v("horizontal list groups cannot be combined with flush list groups.")])])]), _c('b-list-group', {
    attrs: {
      "horizontal": "md"
    }
  }, [_c('b-list-group-item', [_vm._v("Chocolate cake")]), _c('b-list-group-item', [_vm._v("Cookie pie")]), _c('b-list-group-item', [_vm._v("Cheesecake")]), _c('b-list-group-item', [_vm._v("Cotton candy")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }