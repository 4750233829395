var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Contextual Classes"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeListGroupContextual) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use contextual variants to style list items with a stateful background and color, via the")]), _c('code', [_vm._v("variant")]), _c('span', [_vm._v("prop.")])]), _c('b-list-group', [_c('b-list-group-item', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Pie fruitcake jelly-o ")]), _c('b-list-group-item', {
    attrs: {
      "variant": "secondary"
    }
  }, [_vm._v(" halvah icing marshmallow ")]), _c('b-list-group-item', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Cake caramels donut danish muffin biscuit ")]), _c('b-list-group-item', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Chocolate cake pie lollipop ")]), _c('b-list-group-item', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(" Apple pie toffee pudding gummi bears ")]), _c('b-list-group-item', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(" Jujubes chupa chups cheesecake tart ")]), _c('b-list-group-item', {
    attrs: {
      "variant": "dark"
    }
  }, [_vm._v(" Candy fruitcake bonbon sesame snaps dessert ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }