var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Buttons"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeListGroupActionButton) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Or if you prefer ")]), _c('code', [_vm._v("<button>")]), _c('span', [_vm._v(" elements over links, set the ")]), _c('code', [_vm._v("button")]), _c('span', [_vm._v(" prop to true.")])]), _c('b-list-group', [_c('b-list-group-item', {
    attrs: {
      "active": "",
      "button": ""
    }
  }, [_vm._v(" Button item ")]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v(" Button item in list ")]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v(" I am a button ")]), _c('b-list-group-item', {
    attrs: {
      "button": "",
      "disabled": ""
    }
  }, [_vm._v(" Disabled button ")]), _c('b-list-group-item', {
    attrs: {
      "button": ""
    }
  }, [_vm._v(" This is a button too ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }