var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Custom content"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeCustom) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-list-group', [_c('b-list-group-item', {
    staticClass: "flex-column align-items-start",
    attrs: {
      "active": ""
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between"
  }, [_c('h5', {
    staticClass: "mb-1 text-white"
  }, [_vm._v(" List group item heading ")]), _c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("3 days ago")])]), _c('b-card-text', {
    staticClass: "mb-1"
  }, [_vm._v(" Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit. ")]), _c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("Donec id elit non mi porta.")])], 1), _c('b-list-group-item', {
    staticClass: "flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" List group item heading ")]), _c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("3 days ago")])]), _c('b-card-text', {
    staticClass: "mb-1"
  }, [_vm._v(" Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit. ")]), _c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("Donec id elit non mi porta.")])], 1), _c('b-list-group-item', {
    staticClass: "flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" Disabled List group item ")]), _c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("3 days ago")])]), _c('b-card-text', {
    staticClass: "mb-1"
  }, [_vm._v(" Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit. ")]), _c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("Donec id elit non mi porta.")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }