var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Disabled Items"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeListGroupDisable) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Set the ")]), _c('code', [_vm._v("disabled")]), _c('span', [_vm._v(" prop on a ")]), _c('code', [_vm._v("<b-list-group-item>")]), _c('span', [_vm._v(" to make it appear disabled (also works with actionalable items. see below).")])]), _c('b-list-group', [_c('b-list-group-item', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(" Cras justo odio ")]), _c('b-list-group-item', [_vm._v("Dapibus ac facilisis in")]), _c('b-list-group-item', [_vm._v("Morbi leo risus")]), _c('b-list-group-item', [_vm._v("Porta ac consectetur ac")]), _c('b-list-group-item', [_vm._v("Vestibulum at eros")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }