var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Icons"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeIcon) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To add an icon before your content to create a list group with icons.")])]), _c('b-list-group', [_c('b-list-group-item', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InstagramIcon",
      "size": "16"
    }
  })], 1), _c('span', [_vm._v("Cupcake sesame snaps dessert marzipan.")])]), _c('b-list-group-item', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "FacebookIcon",
      "size": "17"
    }
  })], 1), _c('span', [_vm._v("Jelly beans jelly-o gummi bears chupa chups marshmallow.")])]), _c('b-list-group-item', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TwitterIcon",
      "size": "16"
    }
  })], 1), _c('span', [_vm._v("Bonbon macaroon gummies pie jelly")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }