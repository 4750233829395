var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-basic')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-disable')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-icon')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-badge')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-action')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-action-button')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-contextual')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-custom')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-flush')], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('list-group-horizontal')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }