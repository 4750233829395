var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Flush"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFlush) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v("flush")]), _c('span', [_vm._v(" prop for borderless list group.")])]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_vm._v("Sweet gummi bears gingerbread carrot")]), _c('b-list-group-item', [_vm._v("Cake macaroon brownie wafer soufflé")]), _c('b-list-group-item', [_vm._v("Jelly beans lemon drops")]), _c('b-list-group-item', [_vm._v("Lollipop ice cream donut chupa chups")]), _c('b-list-group-item', [_vm._v("Chocolate candy canes tootsie roll")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }